import {AuthenticatedTemplate} from "@azure/msal-react";
import {useEffect, useRef, useState} from "react";
import {getMarketingCampaignSpreadSheet} from "../../GraphService";
import {useAppContext} from "../../AppContext";
import './marketing_campaign.sass'
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {updateSearch} from '../../slices/campaigns/campaignSlice';
import {FiSearch} from 'react-icons/fi';
import {IoMdArrowDropdown} from 'react-icons/io';


function TitleButton({rows, row, index, collapseArray, collapseIndividual}) {

    const [lateAssets, campaign, notes, jira, , masJob, masName, url, , ,
        , , liveDate, , devStart, , qaStart, , , signOff, mgr, dev, qe, pm, copy, design] = row;

    const determineSite = (url) => {

        let links = {
            googleCloud: null,
            gitLab: null,
            developmentUrl: null,
            productionURL: null,
            timeStamp: null
        }
        switch (url) {
            case '/p/auto-replenish-subscription/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/auto-replenish-subscription'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/auto-replenish-subscription.git'
                break;
            case '/s/back-to-school/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/back-to-school'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/back-to-school.git'
                break;
            case '/s/barnes-and-noble/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/barnes-and-noble'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/barnes-and-noble.git'
                break;
            case '/p/beauty-hub/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/beauty-hub'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/beauty-hub.git'
                break;
            case '/p/benefit-brow-bar-locator/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/benefit-brow-bar-locator'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/benefit-brow-bar-locator.git'
                break;
            case '/p/black-friday/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/black-friday'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/black-friday.git'
                break;
            case '/p/college-lifestyle/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/college-lifestyle'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/college-lifestyle.git'
                break;
            case '/p/conscious-beauty/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/conscious-beauty'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/conscious-beauty.git'
                break;
            case '/p/credit-service/benefits/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/credit-service/benefits'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/credit-service.git'
                break;
            case '/p/credit-service/learn-and-apply/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/credit-service/learn-and-apply'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/credit-service.git'
                break;
            case '/p/custom-decorator/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/custom-decorator'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/custom-decorator.git'
                break;
            case '/p/customize-your-couch/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/customize-your-couch'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/customize-your-couch.git'
                break;
            case '/s/cyber-monday-deals/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/cyber-monday-deals'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/cyber-monday-deals.git'
                break;
            case '/s/enhance-app/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/enhance-app'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/enhance-app.git'
                break;
            case '/s/fathers-day-gift-guide/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/fathers-day-gift-guide'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/fathers-day-gift-guide.git'
                break;
            case '/s/fathers-day-shipping/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/fathers-day-shipping'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/fathers-day-shipping.git'
                break;
            case '/p/foundation-finder/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/foundation-finder'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/foundation-finder.git'
                break;
            case '/p/fine-jewelry-gifting/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/fine-jewelry-gifting'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/fine-jewelry-gifting.git'
                break;
            case '/s/flower-show/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/flower-show'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/flower-show.git'
                break;
            case '/p/gift-card-rewards/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/gift-card-rewards'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/gift-card-rewards.git'
                break;
            case '/s/gift-guide/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/gift-guide'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/gift-guide.git'
                break;
            case '/p/hair-care-finder/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/hair-care-finder'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/hair-care-finder.git'
                break;
            case '/p/home-design-trends/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/home-design-trends'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/home-design-trends.git'
                break;
            case '/p/jewelry-services/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/jewelry-services'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/jewelry-services.git'
                break;
            case '/s/international-wholesale/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/international-wholesale'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/international-wholesale.git'
                break;
            case '/p/jewelry-trend/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/jewelry-trend'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/jewelry-trend.git'
                break;
            case '/p/lash-guide/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/'  //???
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/lash-guide.git'
                break;
            case '/p/lenscrafters/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/lenscrafters'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/lenscrafters.git'
                break;
            case 'macysbackstage.com':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/backstage'
                links.gitLab = 'https://code.devops.fds.com/CAP/macysbackstage/macysbackstageBSR.git'
                links.developmentUrl = 'https://macysbackstagebsr.staging.devops.fds.com/'
                links.productionURL = ['https://www.macysbackstage.com/', 'https://backstage-app.macys.com/']
                break;
            case '/p/makeup-services/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/makeup-services'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/makeup-services.git'
                break;
            case '/p/market-by-macys/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/market-by-macys'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/market-by-macys.git'
                break;
            case '/p/mattress-quiz':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/mattress-quiz'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/mattress-quiz.git'
                break;
            case '/s/mothers-day-gift-guide/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/mothers-day-gift-guide'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/mothers-day-gift-guide.git'
                break;
            case '/s/mothers-day-shipping/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/mothers-day-shipping'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/mothers-day-shipping.git'
                break;
            case '/s/own-your-style/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/own-your-style'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/own-your-style.git'
                break;
            case '/s/own-your-style/dress-trends/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/dress-destination'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/dress-destination.git'
                break;
            case '/s/own-your-style/swim-finder/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/swim-finder'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/swim-finder.git'
                break;
            case '/s/own-your-style/womens-wear-to-work/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/womens-wear-to-work'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/womens-wear-to-work.git'
                break;
            case '/s/personal-stylist/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/personal-stylist'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/personal-stylist.git'
                break;
            case '/s/purpose/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/purpose'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/purpose.git'
                break;
            case '/s/promotional-details/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/promotional-details'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/promotional-details.git'
                break;
            case '/s/promotional-details/registry':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/promotional-details'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/promotional-details.git'
                break;
            case '/s/ralph-lauren-gift-guide/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/ralph-lauren-gift-guide'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/ralph-lauren-gift-guide.git'
                break;
            case '/s/registry-guide/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/registry-guide'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/registry-guide.git'
                break;
            case '/s/registry-top-reasons/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/registry-top-reasons'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/registry-top-reasons.git'
                break;
            case '/p/rugs-store-locator/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/'  //???
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/rugs-store-locator.git'
                break;
            case '/p/skincare-solutions-treatments/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/skincare-solutions-treatments'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/skincare-solutions-treatments.git'
                break;
            case '/social/fireworks/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/fireworks'
                links.gitLab = 'https://code.devops.fds.com/imp/fireworks.git'
                break;
            case '/social/smart-watch-comparison/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/smart-watch-comparison'
                links.gitLab = 'https://code.devops.fds.com/imp/smart-watch-comparison.git'
                break;
            case '/s/sports-fan-shop/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/sports-fan-shop'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/sports-fan-shop.git'
                break;
            case '/p/star-money-days/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/star-money-days'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/star-money-days.git'
                links.timeStamp = `?nowDate=${new Date().toISOString().split('T')[0]}T05:00:02.000Z`
                break;
            case '/s/swim-finder/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/swim-finder'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/swim-finder.git'
                break;
            case '/s/sustainability/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/sustainability'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/sustainability.git'
                break;
            case '/p/tax-free/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/tax-free'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/tax-free.git'
                break;
            case '/p/ten-days-of-glam/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/ten-days-of-glam'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/ten-days-of-glam.git'
                break;
            case '/s/toys-r-us/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/toys-r-us'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/toys-r-us.git'
                break;
            case '/s/valentines-day-gift-guide/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/valentines-day-gift-guide'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/valentines-day-gift-guide.git'
                break;
            case '/s/valentines-day-shipping/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/valentines-day-shipping'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/valentines-day-shipping.git'
                break;
            case '/p/watch-trend/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/watch-trend'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/watch-trend.git'
                break;
            case '/p/wedding-and-engagement-rings/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/wedding-and-engagement-rings'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/wedding-and-engagement-rings.git'
                break;
            case '/s/wedding-shop/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/wedding-shop'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/IMP/wedding-shop.git'
                break;
            case '/p/wine-shop/':
                links.googleCloud = 'https://console.cloud.google.com/storage/browser/assets.mcomnyapps.net/wine-shop'
                links.gitLab = 'https://code.devops.fds.com/marketing-campaigns/CE/wine-shop.git'
                links.timeStamp = `?nowDate=${new Date().toISOString().split('T')[0]}T05:00:02.000Z`
                break;
            default:
                break;
        }

        return (
            <>
                <div>
                    <img src="images/google-cloud-icon.svg" alt="Google Cloud Icon" className="vendor-icon"/>
                </div>
                <div>
                    <a href={links.googleCloud} target="_blank" rel="noopener noreferrer" key={index + 'a'}>Google Cloud - {links.googleCloud?.split("/").pop()}</a>
                </div>

                <div>
                    <img src="images/gitlab-icon.svg" alt="Google Cloud Icon" className="vendor-icon"/>
                </div>
                <div>
                    <a href={links.gitLab} target="_blank" rel="noopener noreferrer" key={index + 'b'}>{links.gitLab}</a>
                </div>

                {
                    links.developmentUrl && (<>
                        <div>Development URL</div>
                        <div>
                            <a href={links.developmentUrl} target="_blank" rel="noopener noreferrer" key={index + 'c'}>{links.developmentUrl}</a>
                        </div>
                    </>)
                }
                {
                    links.productionURL && (<>
                        <div>Production URL</div>
                        <div>
                            {links.productionURL.map((link, i) => <a href={link} target="_blank" rel="noopener noreferrer" key={index + 'd' + i}>{link}</a>)}
                        </div>
                    </>)
                }
                {
                    links.timeStamp && (<>
                        <div>Time stamp</div>
                        <div>
                            <button onClick={(e) => navigator.clipboard.writeText(e.target.innerText)}>{links.timeStamp}</button>
                        </div>
                    </>)
                }
            </>
        )
    }

    return (
        <div className={`mc-cell-container${collapseArray[index] ? ' job--collapsed' : ''}${notes.toLowerCase().includes("killed") ? ' mc-cell-container--killed' : ''}${notes.toLowerCase().includes("signed off") ? ' mc-cell-container--signed-off' : ''}`}>
            <div className="job-number-job-name">
                <button type="button" onClick={() => collapseIndividual(index)}>
                    {/^\w{3,4}\s*WEEK\s*\d$/i.test(notes.trim()) ? <h2>{notes}</h2> : <>{masJob} - {masName}</>}
                </button>
            </div>

            <div>{rows[0][0]}</div>
            <div>{lateAssets}</div>

            <div>{rows[0][1]}</div>
            <div>{campaign}</div>

            <div>{rows[0][2]}</div>
            <div>{notes}</div>

            <div>{rows[0][3]}</div>
            <div>
                <a href={`https://jiramst.federated.fds/browse/${jira}`} target="_blank" rel="noopener noreferrer">
                    {jira}
                </a>
            </div>

            <div>{rows[0][5]}</div>
            <div>
                <button onClick={(e) => navigator.clipboard.writeText(e.target.innerText)}>{masJob}</button>
            </div>

            <div>{rows[0][6]}</div>
            <div>
                <button onClick={(e) => navigator.clipboard.writeText(e.target.innerText)}>{masName}</button>
            </div>

            <div>{rows[0][7]}</div>
            <div>
                <a href={`https://macys.com${url.trim()}`} target="_blank" rel="noopener noreferrer">
                    {url.trim()}
                </a>
            </div>

            <div>{rows[0][12]}</div>
            <div>
                <button onClick={(e) => navigator.clipboard.writeText(e.target.innerText)}>{liveDate}</button>
            </div>

            <div>{rows[0][14]}</div>
            <div>
                <button onClick={(e) => navigator.clipboard.writeText(e.target.innerText)}>{devStart}</button>
            </div>

            <div>{rows[0][16]}</div>
            <div>
                <button onClick={(e) => navigator.clipboard.writeText(e.target.innerText)}>{qaStart}</button>
            </div>

            <div>{rows[0][19]}</div>
            <div>
                <button onClick={(e) => navigator.clipboard.writeText(e.target.innerText)}>{signOff}</button>
            </div>

            <div>{rows[0][20]}</div>
            <div>
                <a href={`mailto:karen.green@yourcompany.com?cc=peter.jones@yourcompany.com&subject=${masJob} - ${masName}`}>{mgr}</a>
            </div>

            <div>{rows[0][21]}</div>
            <div>
                <a href={`mailto:karen.green@yourcompany.com?cc=peter.jones@yourcompany.com&subject=${masJob} - ${masName}`}>{dev}</a>
            </div>

            <div>{rows[0][22]}</div>
            <div>
                <a href={`mailto:karen.green@yourcompany.com?cc=peter.jones@yourcompany.com&subject=${masJob} - ${masName}`}>{qe}</a>
            </div>

            <div>{rows[0][23]}</div>
            <div>
                <a href={`mailto:karen.green@yourcompany.com?cc=peter.jones@yourcompany.com&subject=${masJob} - ${masName}`}>{pm}</a>
            </div>

            <div>{rows[0][24]}</div>
            <div>
                <a href={`mailto:karen.green@yourcompany.com?cc=peter.jones@yourcompany.com&subject=${masJob} - ${masName}`}>{copy}</a>
            </div>

            <div>{rows[0][25]}</div>
            <div>
                <a href={`mailto:karen.green@yourcompany.com?cc=peter.jones@yourcompany.com&subject=${masJob} - ${masName}`}>{design}</a>
            </div>

            {url && determineSite(url)}
        </div>
    )
}

export default function MarketingCampaign() {

    const app = useAppContext();
    const dispatch = useAppDispatch();

    const [rows, setRows] = useState();
    const [collapseGlobal, setCollapseGlobal] = useState(true);
    const [collapseArray, setCollapseArray] = useState([]);
    const [developer, setDeveloper] = useState('ALL');

    const search = useAppSelector(({campaign}) => campaign.search);

    const searchRef = useRef();

    const collapseIndividual = (index) => {
        setCollapseArray(prevState => {
            let newState = [...prevState];
            newState[index] = !prevState[index]
            return newState
        })
    }

    useEffect(() => {
        const loadRows = async () => {
            if (app.user && !rows) {
                try {
                    const rows = await getMarketingCampaignSpreadSheet(app.authProvider);
                    setRows([...rows.text]);
                    setCollapseArray(new Array(rows.text.length).fill(true))
                    setDeveloper(app.user?.displayName || 'ALL')
                } catch (err) {
                    app.displayError(err.message);
                }
            }
        };

        loadRows();
    });

    const modifySearch = () => dispatch(updateSearch(searchRef.current.value));

    const displayExcelData = (rows) => {
        return rows.slice(1).filter(row => developer === 'ALL' ? true : row.map(cell => cell.toLowerCase()).some(word => word.includes(developer.toLowerCase()))).filter((row) => row.map(cell => cell.toLowerCase()).some(word => word.includes(search.toLowerCase()))).map((row, i) => {
            return (
                <TitleButton rows={rows} row={row} key={i} index={i} collapseArray={collapseArray} collapseIndividual={collapseIndividual}/>
            )
        });
    }

    if (!rows) {
        return (<div className="loading">Loading</div>)
    }

    const collapseLogic = () => {
        setCollapseGlobal(!collapseGlobal);
        setCollapseArray(collapseArray.map(() => !collapseGlobal));
    }

    return (
        <AuthenticatedTemplate>
            <menu id="campaigns-top-menu">
                <div id="campaigns-top-menu-wrapper">
                    <div id="campaign-search-bar">
                        <input type="text" name="search" ref={searchRef} placeholder="Search" onKeyDown={e => e.key === 'Enter' && modifySearch()}/>
                        <button type="button" onClick={() => modifySearch()}><FiSearch /></button>
                    </div>

                    <select
                        defaultValue={developer}
                        onChange={e => setDeveloper(e.target.value)}
                    >
                        <option value="ALL">All</option>
                        {[...new Set(rows.map(row => row[21].trim()))].slice(1).sort().filter(n => n).map((dev, key) => <option key={key} value={dev}>{dev}</option>)}
                    </select>
                    <button id="collapse-all-button" className={`${!collapseGlobal && 'btn--collapsed'}`} type="button" onClick={() => collapseLogic()}>
                        <IoMdArrowDropdown />
                    </button>
                </div>
            </menu>
            <div id="marketing-campaign-table">
                {displayExcelData(rows)}
            </div>
        </AuthenticatedTemplate>
    )

}
