import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

export interface campaignState {
    search: string,
    collapseButton: boolean,
    collapseAll: []
}

// Define the initial state using that type
const initialState: campaignState = {
    search: '',
    collapseButton: false,
    collapseAll: []
}

export const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        updateSearch: (state, action: PayloadAction<string>) => {
            state.search = action.payload
        },
        setCollapseButton: (state, action: PayloadAction<boolean>) => {
            state.collapseButton = action.payload
        },
        setCollapseAll: (state, action: PayloadAction<[]>) => {
            state.collapseAll = action.payload
        },
    }
})

export const { updateSearch, setCollapseButton, setCollapseAll } = campaignSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSearch = (state: RootState) => state.campaign.search

export default campaignSlice.reducer
