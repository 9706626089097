import {AuthenticatedTemplate} from '@azure/msal-react';
import {getSharepointDrive} from '../../GraphService';
import {useAppContext} from '../../AppContext';
import {useEffect, useState} from 'react';
import './at_a_glance.sass';


export default function AtAGlance() {

    const app = useAppContext();

    const [driveData, setDriveData] = useState();

    useEffect(() => {
        const loadSharepointData = async () => {
            if (app.user && !driveData) {
                try {
                    const data = await getSharepointDrive(
                        app.authProvider,
                        "https://graph.microsoft.com/v1.0/sites/ef72fb3d-8256-4fee-8697-0486d8003664/drives/b!Pfty71aC7k-GlwSG2AA2ZFERcEUQUN5EuK-78WfduCzl13tXqWzwQJwulk4mY7G2/root/children"
                    );
                    setDriveData(data.value);
                } catch (err) {
                    app.displayError(err.message);
                }
            }
        };

        loadSharepointData();
    });

    const navigateDrive = async (uri) => {
        if (app.user) {
            try {
                const data = await getSharepointDrive(
                    app.authProvider,
                    uri
                );
                setDriveData(data.value);
            } catch (err) {
                app.displayError(err.message);
            }
        }
    }

    const searchAndDownload = (uri) => {
        console.log(uri);
    }

    const displaySharepointData = () => {
        return driveData.map(driveInfo => {

            return (
                <>
                    {driveInfo.hasOwnProperty('folder') ? (<div className="buttons-row">
                        <button className="driveItems" onClick={() => navigateDrive(`${driveInfo.parentReference.path}/${driveInfo.name}:/children`)}>{driveInfo.name}</button>
                        <button className="download-special" onClick={() => searchAndDownload(`${driveInfo.parentReference.path}/${driveInfo.name}:/children`)}>Search & Download</button>
                    </div>) : <a href={driveInfo.webUrl} target="_blank" rel="noopener noreferrer">{driveInfo.name}</a>}
                </>
            )
        })
    }

    if (!driveData) {
        return (<div>Loading</div>)
    }

    const backButton = () => {

        let path = driveData[0]?.parentReference.path.substring(0, driveData[0]?.parentReference.path.lastIndexOf('/'))

        const re = new RegExp(/\/root:$/);

        if (re.test(path)) {
            path = path.replace(re, '/root').concat('/children');
        } else {
            path = path.concat(':/children');
        }

        return (<button id="glance-back-button" disabled={!path.includes('/root')} onClick={() => navigateDrive(`https://graph.microsoft.com/v1.0${path}`)}>{String.fromCodePoint('0x1F519')}</button>);
    }

    return (
        <AuthenticatedTemplate>
            <div id="glance-table-container">
                {displaySharepointData()}
                {backButton()}
            </div>
        </AuthenticatedTemplate>
    )
}
